body {
  margin: 0;
  font-family: "Inter", sans-serif;
  line-height: 1.3;
  font-size: 16px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

div#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #eeeeee;
  section {
    width: 100%;
    flex: 1;
    max-width: 1500px;
    align-self: center;
    .MuiCard-root {
      min-height: calc(100vh - 146px);
    }
  }
}

@media (max-width: 480px) {
  body {
    font-size: 13px;
  }
}
